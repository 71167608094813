* {
  margin: 0;
  padding: 0;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body {
  background: #F7F7F7;
  margin: 0;
  font-family: none, serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.bottom {
  position: fixed;
// position:absolute;
  left: 0;
  bottom: 0;
  z-index: 999;
  background-color: #ffffff;
  width: 100%;
  border-top: solid 1px var(--adm-color-border);
}

.body {
  position: relative;
}


.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

#root {
// padding-top: 44px;
}

.top {
  flex: 0;
  border-bottom: solid 1px var(--adm-color-border);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 19;
  width: 100%;
  height: 44px;
  background-color: #FFF;
  border-bottom: #0000002e 1px solid !important;
}

@primary-color: #1890ff;